@font-face {
  font-family: 'ProximaNova';
  src: url('../assets/fonts/ProximaNova-Bold.woff2') format('woff2'),
      url('../assets/fonts/ProximaNova-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ProximaNova ';
  src: url('../assets/fonts/ProximaNovaT-Thin.woff2') format('woff2'),
      url('../assets/fonts/ProximaNovaT-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ProximaNova';
  src: url('../assets/fonts/ProximaNova-Regular.woff2') format('woff2'),
      url('../assets/fonts/ProximaNova-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ProximaNova';
  src: url('../assets/fonts/ProximaNova-Semibold.woff2') format('woff2'),
      url('../assets/fonts/ProximaNova-Semibold.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ProximaNova';
  src: url('../assets/fonts/ProximaNova-Extrabld.woff2') format('woff2'),
      url('../assets/fonts/ProximaNova-Extrabld.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
