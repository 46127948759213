// Use this file to override styling of NG-ZORRO components
@import "./utils";

.trigger {
  font-size: rem(18px);
  line-height: rem(64px);
  padding: 0 rem(24px);
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.logo {
  height: rem(32px);
  background: rgba(255, 255, 255, 0.2);
  margin: rem(16px);
}

nz-layout {
  min-height: 100% !important;
}

nz-header {
  background: #fff !important;
  padding: 0 !important;
}

nz-content {
  margin: rem(15px) rem(20px);
}

nz-breadcrumb {
  margin: rem(16px) 0;
}

.inner-content {
  padding: rem(24px);
  background: #fff;
  min-height: rem(360px);
}

nz-footer {
  text-align: center;
}

.custom-modal {
  width: 100% !important;
  max-width: rem(720px);

  .ant-modal-body {
    padding: 0;
  }
}

.custom-card {
  .ant-card-body {
    padding: rem(10px) rem(5px);
  }
}

.auth-container {
  nz-content {
    background-color: #f0f2f5;

    .inner-content{
      width: 100%;
    }
  }
}
.ant-form-horizontal .ant-form-item-label {
  padding-bottom: 0!important;
}
