/* You can add global styles to this file, and also import other style files */
// You can add global styles to this file, and also import other style files
@import 'styles/utils';
@import 'styles/fonts';
@import 'styles/palette';
@import 'styles/overrides';
@import 'styles/helpers';

.primary-color{
  color: #1890ff;
}

.bg-light-blue{
  background-color: rgb(242, 243, 244);
}

.site-page-header {
  border: 1px solid #ebedf0;
}



.card {
  width: rem(215px);
  height: rem(175px);
  position: relative;
  box-shadow: 0 rem(2px) rem(10px) rgba(0, 0, 0, 0.2);

  .card-display {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  &:hover{
    .card-info{
      display: flex!important;
    }
  }
  .card-info {
    position: absolute;
    right: 0;
    display: flex;
    align-items: center;
    padding: rem(2px) rem(5px);
    font-size: rem(14px);
    span {
      padding: rem(2px) rem(5px);
      margin-right: rem(2px);
      background: rgb(255 255 255 / 85%);
      color: #000;
      font-weight: bold;
      cursor: pointer;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .card-profile {
    background-color: #f3f3f3;
    display: flex;
    align-items: center;
    width: 100%;
    padding: rem(8px) rem(8px);
    position: absolute;
    bottom: 0;
    .card-profile__text {
      display: flex;
      justify-content: space-between;
      width: 100%;
      div {
        display: inline-block;
        color: #334454;
        font-size: rem(12px);
        font-weight: bold;
        margin-bottom: 0;
      }
    }
  }
}

.content-block-add-button-container {
  display: inline-flex;
  align-items: center;

  button {
    border-color: #b4ddff;
    background: #b4ddff;
  }

  span{
    width: rem(200px);
    left: rem(50px);
  }

  &:hover {
    .ant-btn-primary {
      border-color: #1890ff;
      background: #1890ff;
    }
    span{
      display: inline-block!important;
    }
  }
}
